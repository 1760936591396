import { VFC } from 'react';
import { Truck, Users } from 'react-feather';
import { PdfOutputButton } from './PdfOutputButton';
import { DeleteButton } from './DeleteButton';
import { useParams } from 'react-router-dom';
import { useJian } from '../../../hooks/useJian';
import { useNavigateFromJianDetail, useTransportedPatient } from '../../../hooks/useJianDetail';
import { ErrorMessage } from './ErrorMessage';
import { useErrorCheck } from '../../../hooks/useErrorCheck';
import { ActionPanelButton } from '../../../components/ActionPanelButton';

export const MainActionPanel: VFC = () => {
  const { jianId, infoType, year } = useParams<{
    jianId: string;
    infoType: string;
    year: string;
  }>();
  const jian = useJian(jianId);
  const navigate = useNavigateFromJianDetail();
  const { errorCount, warningCount } = useErrorCheck();
  const patientIds = jian?.patientInfos.map((patientInfo) => patientInfo?.patientInfoId);
  const { transportedPatientCount } = useTransportedPatient(jianId || '', patientIds || []);
  const shouldShowTransportedPatientCount = process.env.REACT_APP_DEPLOYMENT_REGION === 'Fujisawa';

  return (
    <div className="container mx-auto bg-darkblue_10 rounded flex content-center divide-x divide-gray-400">
      <div className="h-24 px-4 py-6 my-auto flex flex-shrink-0 gap-4 items-start max-lg:flex-col max-lg:h-40">
        {/* left block */}
        <ActionPanelButton
          type="light"
          onClick={() =>
            navigate(
              `/${year}/jian/${jianId}/dispatch-info/${jian?.dispatchInfoId}?categoryName=出動概要`
            )
          }
          label={'出場情報'}
          icon={<Truck size={24} />}
          isSelected={infoType === 'dispatch-info'}
          error={errorCount?.dispatch}
          warning={warningCount?.dispatch}
        />
        <ActionPanelButton
          type="light"
          onClick={() =>
            navigate(
              `/${year}/jian/${jianId}/patient-info/${
                jian?.patientInfos.at(0)?.patientInfoId ?? ''
              }?categoryName=基本情報`
            )
          }
          label={'傷病者情報'}
          icon={<Users size={24} />}
          isSelected={infoType === 'patient-info'}
          error={errorCount?.patient}
          warning={warningCount?.patient}
        />
      </div>

      <div className="basis-2/3 p-4 gap-x-6 flex items-center max-md:p-2">
        {/* middle block */}
        {shouldShowTransportedPatientCount && (
          <div className="self-center">
            <p className="text-l font-bold text-darkblue_100">
              搬送者数：{transportedPatientCount}人
            </p>
          </div>
        )}
        <ErrorMessage />
      </div>

      <div className="p-4 flex flex-shrink-0 gap-4 content-center items-center">
        {/* right block */}
        <DeleteButton />
        <PdfOutputButton />
      </div>
    </div>
  );
};
