import { components } from '../../../schema/hospital-management';
import { HeaderCell } from './HeaderCell';
import { HospitalCell } from './HospitalCell';
import {
  decodeEmbodimentCode,
  decodeJurisdictionCode,
  decodeEstablishmentCode,
} from '../../../utils/hospitalCode';

type Props = {
  hospitals: components['schemas']['Hospital'][];
  onSelectHospital: (hospital: components['schemas']['Hospital']) => void;
};

export const HospitalTable: React.VFC<Props> = ({ hospitals, onSelectHospital }) => {
  const region = process.env.REACT_APP_DEPLOYMENT_REGION;
  return (
    <table aria-label="病院一覧" className="w-max h-max border-separate border-spacing-0">
      <thead>
        <tr>
          <HeaderCell className="w-28">医療機関名</HeaderCell>
          <HeaderCell className="w-24">国コード</HeaderCell>
          <HeaderCell className="w-28">医療機関コード</HeaderCell>
          <HeaderCell className="w-24">設立別</HeaderCell>
          <HeaderCell className="w-20">管内・外</HeaderCell>
          <HeaderCell className="w-24">病院分類</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {hospitals.map((hospital) => (
          <tr
            key={hospital.hospitalId}
            aria-label={hospital.hospitalName}
            className="hover:bg-slate-100 cursor-pointer"
            onClick={() => onSelectHospital(hospital)}
          >
            <HospitalCell className="w-28">{hospital.hospitalName}</HospitalCell>
            <HospitalCell className="w-24">{hospital.organizationCode}</HospitalCell>
            <HospitalCell className="w-28">
              {region === 'Fujisawa' ? hospital.ownCode : hospital.conveyorCode}
            </HospitalCell>
            <HospitalCell className="w-24">
              {decodeEstablishmentCode(hospital.hospitalEstablishmentType)}
            </HospitalCell>
            <HospitalCell className="w-20">
              {decodeJurisdictionCode(hospital.jurisdiction)}
            </HospitalCell>
            <HospitalCell className="w-24">
              {decodeEmbodimentCode(hospital.hospitalEmbodimentType)}
            </HospitalCell>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
