import { Suspense, useCallback, VFC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import { ErrorFallBack } from '../../ErrorFallBack';
import { JianListDateCell } from './JianListDateCell';
import { JianListCheckboxCell } from './JianListCheckboxCell';
import { JianListItemCell } from './JianListItemCell';
import { useSelectedOrCurrentYear } from '../../../hooks/useYears';

type JianListItemProps = {
  jian: {
    jianId: string;
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
    fields: {
      fieldId: string;
      fieldType: string;
      fieldName: string;
      value?: string | string[] | number | null;
    }[];
  };
  isSelected: (jianId: string) => boolean;
  selectJianId: (jianId: string | string[]) => void;
  deselectJianId: (jianId: string | string[]) => void;
};

export const JianListItem: VFC<JianListItemProps> = ({
  jian,
  isSelected,
  selectJianId,
  deselectJianId,
}) => {
  const { jianId, infoType, infoTypeId, fields } = jian;
  const navigate = useNavigate();
  const year = useSelectedOrCurrentYear();
  const onClick = useCallback(() => {
    navigate(
      `/${year}/jian/${jianId}/${infoType}/${infoTypeId}?categoryName=${
        infoType === 'dispatch-info' ? '出動概要' : '基本情報'
      }`
    );
  }, [year, jianId, infoType, infoTypeId, navigate]);
  const awarenessDateTime = fields.find(
    (field) => field.fieldId === 'c52159d0-24dd-4736-aa0b-af50b27d3499'
  )?.value as string | undefined;

  // 出場情報のみを展開して表示する
  return (
    <tr className="relative h-32 border-b-[1px] border-b-white" tabIndex={0}>
      {/** checkbox */}
      <JianListCheckboxCell
        checked={isSelected(jianId)}
        onChange={(checked: boolean) => {
          if (checked) {
            selectJianId(jianId);
          } else {
            deselectJianId(jianId);
          }
        }}
      />

      {/** 覚知時刻 */}
      <JianListDateCell dateString={awarenessDateTime ?? ''} onClick={onClick} />

      {
        /**値の展開 */
        fields.map((field) => {
          return (
            <ErrorBoundary key={field.fieldId} FallbackComponent={ErrorFallBack}>
              <Suspense fallback={<JianListItemLoading />}>
                <JianListItemCell
                  fieldType={field.fieldType}
                  fieldValue={field.value}
                  onClick={onClick}
                />
              </Suspense>
            </ErrorBoundary>
          );
        })
      }
    </tr>
  );
};

const JianListItemLoading = () => {
  return (
    <div>
      <div className="animate-pulse bg-gray-200 h-32 w-full"></div>
    </div>
  );
};
